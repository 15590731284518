import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleCasePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(
    private title: Title,
    private titleCasePipe: TitleCasePipe) { }

  public setTitle(title: string) {
    const prefix = 'SudoCloud Technologies - ';
    const properCaseTitle = this.titleCasePipe.transform(title);
    this.title.setTitle(`${prefix}${properCaseTitle}`);
  }
}
