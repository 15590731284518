import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/shared/service/auth.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.scss']
})
export class CreateBlogComponent implements OnInit {
  submitting: boolean;

  constructor(private fb: FormBuilder, private http: HttpClient, private authService: AuthService) { }

  blogCreateForm = this.fb.group({
    title: ['', Validators.required],
    blog: ['', Validators.required],
    tags: ['']
  });

  ngOnInit() {

  }

  async onSubmit() {
    this.submitting = true;
    console.warn(this.blogCreateForm.value);
    const token = await this.authService.retrieveToken$.toPromise();
    console.log('Found token - ');
    console.log(token);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      })
    };
    this.http.post(`${environment.apiUrl}/posts`,
      this.blogCreateForm.value, httpOptions).subscribe((createdBlog) => {
        console.log(createdBlog);
      }, (error) => {
        console.error(error);
      });
  }
}
