import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './auth/login/login.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { CreateBlogComponent } from './admin/blog/create-blog/create-blog.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { LogoutComponent } from './auth/logout/logout.component';
import { HttpClientModule } from '@angular/common/http';
import { ListBlogComponent } from './blog/list-blog/list-blog.component';
import { ExcerptPipe } from './excerpt.pipe';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { TitleCasePipe } from '@angular/common';
import { ProfileComponent } from './auth/profile/profile.component';
import { FooterComponent } from './footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavigationComponent,
    AboutComponent,
    LoginComponent,
    CallbackComponent,
    CreateBlogComponent,
    LogoutComponent,
    ListBlogComponent,
    ExcerptPipe,
    BlogPostComponent,
    LoadingSpinnerComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    CKEditorModule,
    ReactiveFormsModule
  ],
  providers: [
    Title,
    TitleCasePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
