import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './shared/service/auth.service';
import { TitleService } from './shared/service/title.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  collapsed = true;
  title: string;

  constructor(
    private router: Router,
    private authService: AuthService,
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.authService.localAuthSetup();
    this.title = this.router.url;
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const parsedTitle = val.url.replace('/', '');
        if (parsedTitle) {
          this.titleService.setTitle(parsedTitle);
        } else {
          this.titleService.setTitle('Freelancing Solutions');
        }
      }
    });
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }


}
