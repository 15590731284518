import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { LoginComponent } from './auth/login/login.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { CreateBlogComponent } from './admin/blog/create-blog/create-blog.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { LogoutComponent } from './auth/logout/logout.component';
import { ListBlogComponent } from './blog/list-blog/list-blog.component';
import { BlogPostComponent } from './blog/blog-post/blog-post.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'admin/post/create',
    component: CreateBlogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'posts',
    component: ListBlogComponent
  },
  {
    path: 'posts/:id',
    component: BlogPostComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
