import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'excerpt'
})
export class ExcerptPipe implements PipeTransform {

  transform(text: string, length = 120): any {
    const strippedText = text.replace(/(<[^>]+>|<[^>]>|<\/[^>]>)/g, '');

    if (strippedText.length > length) {
      return strippedText.substr(0, length) + '...';
    }
    return strippedText;

  }

}
