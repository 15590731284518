import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/shared/service/blog.service';
import { Title } from '@angular/platform-browser';
import BlogPost from '../models/blog-post';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit, OnDestroy {
  blogPost: any;
  private sub: any;
  loading: boolean;

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogService,
    private location: Location,
    private titleService: Title) { }

  ngOnInit() {

    this.loading = true;
    this.sub = this.route.params.subscribe(params => {
      this.blogService.retrieveBlogPost(params.id).subscribe((blogPost: BlogPost) => {
        this.blogPost = blogPost;
        this.titleService.setTitle(`SudoCloud Technologies - ${blogPost.title} by ${blogPost.createdBy}`);
        this.loading = false;
      });
    });
  }

  toTop() {
    window.scroll(0, 0);
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
