import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  httpOptions = {
    headers: new HttpHeaders({
      'x-api-key': `KqBZDRmCtB8JraKGdizt98sJYArrSxNB5fEmWknO`
    })
  };

  constructor(private httpClient: HttpClient) { }


  public retrieveBlogList() {
    return this.httpClient.get(`${environment.apiUrl}/posts`, this.httpOptions);
  }

  public retrieveBlogPost(id: string) {
    return this.httpClient.get(`${environment.apiUrl}/posts/${id}`, this.httpOptions);
  }
}
