import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BlogService } from 'src/app/shared/service/blog.service';

@Component({
  selector: 'app-list-blog',
  templateUrl: './list-blog.component.html',
  styleUrls: ['./list-blog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListBlogComponent implements OnInit {
  blogList;

  constructor(public blogService: BlogService) { }

  ngOnInit() {
    this.blogService.retrieveBlogList()
      .subscribe((blogPage) => {
        this.blogList = blogPage;
      });
  }

}
