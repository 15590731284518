import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError, concat } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as jwtDecode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: 'sudocloud.auth0.com',
      client_id: 'mBhdGzR2Kal61OIXlY17uQf5i2w29gIT',
      audience: 'https://serverless-cms-rest.sudocloudtech.com',
      response_type: 'token id_token',
      redirect_uri: `${window.location.origin}/callback`,
      scope: 'openid profile write:blog',
    })
  ) as Observable<Auth0Client>).pipe(
    catchError(err => {
      console.log('Error occurred creating client');
      console.log(err);
      return throwError(err);
    })
  );

  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  retrieveToken$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getTokenSilently()))
  );
  retrieveClaims$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getIdTokenClaims()))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  loggedIn: boolean = null;

  constructor(private router: Router) { }

  getUser$(options?): Observable<any> {
    console.log('get user1111111111111');
    console.log(this.auth0Client$);
    console.log(options);
    this.auth0Client$.toPromise()
      .then((cloient) => {
        console.log(cloient);
      });
    return this.auth0Client$.pipe(
      concatMap(async (client: Auth0Client) => {
        console.log('This is atest');
        const user = await client.getUser(options);
        console.log(user);
        return from(client.getUser(options));
      }),
      tap(user => {
        console.log('user ---');
        console.log(user);
        this.userProfileSubject$.next(user);
      })
    );
  }

  localAuthSetup() {
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap(async (loggedIn: boolean) => {
        if (loggedIn) {
          const token = await this.retrieveToken$.toPromise();
          if (token) {
            await this.storeTokenLocally(token);
          }
          console.log('returning user');
          return this.getUser$();
        }
        console.log('not logged in');
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      this.loggedIn = !!response;
    });
  }

  private async storeTokenLocally(token: string) {

    localStorage.setItem('access_token', token);
    const jsonParsedToken = jwtDecode(token);
    localStorage.setItem('claims', JSON.stringify(jsonParsedToken));
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}/callback`,
        appState: { target: redirectPath }
      });
    });
  }

  handleAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0

    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap((cbRes: RedirectLoginResult) => {
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() => {
        // Redirect callback complete; get user and login status
        return combineLatest(
          this.getUser$(),
          this.isAuthenticated$
        );
      }),
      tap(async () => {
        const token = await this.retrieveToken$.toPromise();
        if (token) {
          await this.storeTokenLocally(token);
        }
      })
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {
      // Redirect to target route after callback processing
      this.router.navigate([targetRoute]);
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: 'mBhdGzR2Kal61OIXlY17uQf5i2w29gIT',
        returnTo: `${window.location.origin}`
      });
    });
  }

}